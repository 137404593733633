import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../.././assets/logo/logo 1.png";
import Design from "../../assets/imgs/UIUXDesign-square.png";
import webDevelopment from "../../assets/imgs/webDesign-square.png";
import MobileDevelopment from "../../assets/imgs/MobileDevelopment-square.png";
import CloudServices from "../../assets/imgs/Cloudcomputing-square.png";
import Card from "react-bootstrap/Card";
import "./Header.scss";
import LanguageSelector from "./../../helper/languageselector";
import { useTranslation } from "react-i18next";

function Header() {
  var hovered = false;

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".desktop-header");
    const scrollTop = window.scrollY;
    scrollTop >= 60
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const itemHover = (e) => {
    hovered = !hovered;
    const header = document.querySelector(".desktop-header");
    const headerItem = document.querySelector(".services");
    if (hovered) {
      header.classList.add("itemHover");
      headerItem.id = "hoveredOn";
      document.querySelector(".dropDownServices").classList.remove("d-none");
    } else {
      header.classList.remove("itemHover");
      headerItem.id = "";
    }
  };

  const closeHeader = () => {
    document.querySelector(".dropDownServices").classList.toggle("d-none");
  };

  const { t, i18n } = useTranslation();

  return (
    <div className="desktop-header">
      <Link exact className="logo" to="/">
        <img src={logo} alt="Logo" />
      </Link>
      <nav className="navBar">
      {/* issue with link having child link */}
        <div
          className="headerItem services"
          onMouseEnter={itemHover}
          onMouseLeave={itemHover}
        >
          {t("SERVICES_LABEL_")}
          <div className="dropDownServices">
            <Link
              exact
              className="serviceCard"
              to="/services/webdevelopment"
              onClick={closeHeader}
            >
              <div className="wrapper">
                <div className="card">
                  <img src={webDevelopment} />
                  <div className="info">
                    <h1>{t("WEB_DEVELOPMENT_LABEL_")}</h1>
                    <p>{t("WEB_DEVELOPMENT_APPLICATION_LABEL_")}</p>
                    <button>{t("READ_MORE_LABEL_")}</button>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              exact
              className="serviceCard"
              to="/services/MobileDevelopment"
              onClick={closeHeader}
            >
              <div className="wrapper">
                <div className="card">
                  <img src={MobileDevelopment} />
                  <div className="info">
                    <h1>{t("MOBILE_DEVELOPMENT_LABEL_")}</h1>
                    <p>{t("MOBILE_DEVELOPMENT_APPLICATION_LABEL_")}</p>
                    <button>{t("READ_MORE_LABEL_")}</button>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              exact
              className="serviceCard"
              onClick={closeHeader}
              to="/services/design"
            >
              <div className="wrapper">
                <div className="card">
                  <img src={Design} />
                  <div className="info">
                    <h1>{t("UI_UX_DESIGN_LABEL_")}</h1>
                    <p>{t("CREATING_UIUX_DESIGNS_LABEL_")}</p>
                    <button>{t("READ_MORE_LABEL_")}</button>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              exact
              className="serviceCard"
              onClick={closeHeader}
              to="/services/cloudcomputing"
            >
              <div className="wrapper">
                <div className="card">
                  <img src={CloudServices} />
                  <div className="info">
                    <h1>{t("CLOUD_SYSTEMS_LABEL_")}</h1>
                    <p>{t("FAST_FLEXIBLE_LABEL_")}</p>
                    <button>{t("READ_MORE_LABEL_")}</button>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <Link
          exact
          className="headerItem about-us"
          to="/about"
        >
          {t("ABOUT_US_LABEL_")}
        </Link>
        <Link
          exact
          className="headerItem contact-us"
          to="/contact"
        >
          {t("CONTACT_US_LABEL_")}
        </Link>
      </nav>
      <LanguageSelector />
    </div>
  );
}

export default Header;
