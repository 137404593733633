import React from "react";
import { useEffect } from "react";
import "./WebDevelopment.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import WebDevelopmentHeroImage from "./../../../assets/imgs/web development21.jpg";
import WebDevelopmentImage from "./../../../assets/imgs/webDev.png";

import { Accordion } from "react-bootstrap-accordion";

import { useTranslation } from "react-i18next";

function WebDevelopment() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="web-dev">
      <div className="hero-section">
        <h1>{t("WEB_DEVELOPMENT_LABEL_")}</h1>
        <p></p>
      </div>
      <section id="web-section" className="web-section section-bg">
        <Container fluid data-aos="fade-up" className="container_section">
          <Row className="row_data">
            <Col
              lg={7}
              className="d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1"
            >
              <div className="content">
                <h3>{t("WEB_DEVELOPMENT_PAGE_TITLE_LABEL_")}</h3>
                <p>{t("WEB_DEVELOPMENT_PAGE_TEXT_LABEL_")}</p>
              </div>
              <div className="accordion-list">
                <ul>
                  <li>
                    <Accordion title={t("INSIGHTS_AND_CONSULTATIONS_LABEL_")}>
                      {t("INSIGHTS_AND_CONSULTATIONS_TEXT_LABEL_")}
                    </Accordion>
                  </li>
                  <li>
                    <Accordion title={t("STARTING_FROM_SCRATCH_LABEL_")}>
                      {t("STARTING_FROM_SCRATCH_TEXT_LABEL_")}
                    </Accordion>
                  </li>
                  <li>
                    <Accordion
                      title={t("REDEVELOPMENT_OPTIMIZATION_ENHANCEMENT_LABEL_")}
                    >
                      {t("REDEVELOPMENT_OPTIMIZATION_ENHANCEMENT_TEXT_LABEL_")}
                    </Accordion>
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              lg={5}
              className="align-items-stretch order-1 order-lg-2 img"
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              <img src={WebDevelopmentImage} alt="Responsive img" />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default WebDevelopment;
