import React from "react";
import { useEffect } from "react";
import "./Design.scss";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import DsignHeroImage from "./../../../assets/imgs/UIUXDesign1.jpg";
import { useTranslation } from "react-i18next";

function CloudComputing() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container_section">
      <div className="hero-section design"></div>
      <section id="services" className="services">
        <Container className="container">
          <header className="section-header">
            <h2>{t("UIUX_DDESIGN_PAGE_TITLE_LABEL_")}</h2>
            <p>{t("UIUX_DDESIGN_PAGE_TEXT_LABEL_")}</p>
          </header>
          <div className="row_data_cloud_computing">
            <Flippy
              flipOnHover={true} // default false
              flipOnClick={true} // default false
              flipDirection="vertical" // vertical or vertical
              style={{ width: "30%", height: "200px" }} /// these are optional style, it is not necessary
            >
              <FrontSide>
                <span className="section-number">{t("PHASE_ONE_LABEL_")}</span>
              </FrontSide>
              <BackSide style={{ backgroundColor: "#3344C0" }}>
                <h4>{t("PRELIMINARY_SELECTION_LABEL_")}</h4>
                <p>{t("PRELIMINARY_SELECTION_TEXT_LABEL_")}</p>
              </BackSide>
            </Flippy>
            <Flippy
              flipOnHover={true} // default false
              flipOnClick={true} // default false
              flipDirection="vertical" // vertical or vertical
              style={{ width: "30%", height: "200px" }} /// these are optional style, it is not necessary
            >
              <FrontSide>
                <span className="section-number">{t("PHASE_TWO_LABEL_")}</span>
              </FrontSide>
              <BackSide style={{ backgroundColor: "#3344C0" }}>
                <h4>{t("QUALIFIERS_LABEL_")}</h4>
                <p>{t("QUALIFIERS_TEXT_LABEL_")}</p>
              </BackSide>
            </Flippy>
            <Flippy
              flipOnHover={true} // default false
              flipOnClick={true} // default false
              flipDirection="vertical" // vertical or vertical
              style={{ width: "30%", height: "200px" }} /// these are optional style, it is not necessary
            >
              <FrontSide>
                <span className="section-number">{t("PHASE_THREE_LABEL_")}</span>
              </FrontSide>
              <BackSide style={{ backgroundColor: "#3344C0" }}>
                <h4>{t("SINGLING_OUT_AND_INITIATION_LABEL_")}</h4>
                <p>{t("SINGLING_OUT_AND_INITIATION_TEXT_LABEL_")}</p>
              </BackSide>
            </Flippy>
            <Flippy
              flipOnHover={true} // default false
              flipOnClick={true} // default false
              flipDirection="vertical" // vertical or vertical
              style={{ width: "30%", height: "200px" }} /// these are optional style, it is not necessary
            >
              <FrontSide>
                <span className="section-number">{t("PHASE_FOUR_LABEL_")}</span>
              </FrontSide>
              <BackSide style={{ backgroundColor: "#3344C0" }}>
                <h4>{t("PROJECT_ESTIMATION_LABEL_")}</h4>
                <p>{t("PROJECT_ESTIMATION_TEXT_LABEL_")}</p>
              </BackSide>
            </Flippy>
            <Flippy
              flipOnHover={true} // default false
              flipOnClick={true} // default false
              flipDirection="vertical" // vertical or vertical
              style={{ width: "30%", height: "200px" }} /// these are optional style, it is not necessary
            >
              <FrontSide>
                <span className="section-number">{t("PHASE_FIVE_LABEL_")}</span>
              </FrontSide>
              <BackSide style={{ backgroundColor: "#3344C0" }}>
                <h4>{t("PROTOTYPING_AND_IMPROVEMENT_LABEL_")}</h4>
                <p>{t("PROTOTYPING_AND_IMPROVEMENT_TEXT_LABEL_")}</p>
              </BackSide>
            </Flippy>
            <Flippy
              flipOnHover={true} // default false
              flipOnClick={true} // default false
              flipDirection="vertical" // vertical or vertical
              style={{ width: "30%", height: "200px" }} /// these are optional style, it is not necessary
            >
              <FrontSide>
                <span className="section-number">{t("PHASE_SIX_LABEL_")}</span>
              </FrontSide>
              <BackSide style={{ backgroundColor: "#3344C0" }}>
                <h4>{t("DEPLOYMENT_LABEL_")}</h4>
                <p>{t("DEPLOYMENT_TEXT_LABEL_")}</p>
              </BackSide>
            </Flippy>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default CloudComputing;
