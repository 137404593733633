import React from "react";
import "./PopupModal.scss"
import Modal from "react-modal";
import FormatDescription from "../FormatDescription/FormatDescription";


function PopupModal(data){
    return(
        <Modal className="modal"
            isOpen={data.isOpen}
            onRequestClose={data.clossPopup}
            contentLabel="My dialog">
            <div className="modal_image_head">
                <img className="modal_img" src={data.modalImage} alt="img" />
            </div>
            <div className="modal_description">
                <FormatDescription id={data.id}/>
            </div>
            <div className="modal_button">
                <button className="modal_close_button" onClick={data.clossPopup}>Close</button>
            </div>
        </Modal>
    );
}

export default PopupModal;



