import React from "react";
import { useTranslation } from "react-i18next";
import "./FormatDescription.scss"

function FormatDescription(data){
    const { t, i18n } = useTranslation();

    return(
        <div>
            {(() => {
                switch (data.id) {
                case 1:
                {/* add to translation file */}
                    return <div>
                                <p>
                                {t("RESEARCH_AND_ANALYSIS_LABEL_FIRST_")}<b>{t("RESEARCH_AND_ANALYSIS_LABEL_SECOND_")}</b>{t("RESEARCH_AND_ANALYSIS_LABEL_THIRD_")}
                                </p>
                                <p>
                                {t("RESEARCH_AND_ANALYSIS_LABEL_FOURTH_")}
                                </p>
                            </div>
                case 2:
                    return <div>
                                <p>
                                    {t("PLANNING_AND_STRATEGY_LABEL_FIRST_")}
                                </p>
                                <p>
                                    {t("PLANNING_AND_STRATEGY_LABEL_SECOND_")}
                                </p>
                                <p>
                                    {t("PLANNING_AND_STRATEGY_LABEL_THIRD_")}
                                </p>
                                <p>
                                    {t("PLANNING_AND_STRATEGY_LABEL_FOURTH_")}
                                </p>
                                <p>
                                    {t("PLANNING_AND_STRATEGY_LABEL_FIFTH_")}
                                </p>
                            </div>
                case 3:
                    return <div>
                                <p>
                                    {t("DESIGNING_LABEL_FIRST_")}
                                </p>
                                <p>
                                    {t("DESIGNING_LABEL_SECOND_")}
                                </p>
                                <p>
                                    {t("DESIGNING_LABEL_THIRD_")}
                                </p>
                                <p>
                                    {t("DESIGNING_LABEL_FOURTH_")}<b>{t("DESIGNING_LABEL_FIFTH_")}</b>{t("DESIGNING_LABEL_SIXTH_")}
                                </p>
                           </div>
                case 4:
                    return <div>
                                <p>
                                    {t("CONTENT_CREATION_LABEL_FIRST_")}
                                </p>
                                <p>
                                    {t("CONTENT_CREATION_LABEL_SECOND_")}
                                </p>
                                <p>
                                    {t("CONTENT_CREATION_LABEL_THIRD_")}<b>{t("CONTENT_CREATION_LABEL_FOURTH_")}</b>{t("CONTENT_CREATION_LABEL_FIFTH_")}
                                </p>
                                <p>
                                    {t("CONTENT_CREATION_LABEL_SIXTH_")}
                                </p>
                                <p>
                                    {t("CONTENT_CREATION_LABEL_SEVENTH_")}
                                </p>
                           </div>
                case 5:
                    return <div>
                                <p>
                                    {t("DEVELOPMENT_LABEL_FIRST_")}
                                </p>
                                <ul>
                                {/* need to be modified */}
                                    <li> 
                                        {t("DEVELOPMENT_LABEL_SECOND_")}
                                        <p>
                                            {t("DEVELOPMENT_LABEL_THIRD_")}
                                        </p>
                                        <p>
                                            {t("DEVELOPMENT_LABEL_FOURTH_")}
                                        </p>
                                    </li>
                                    <li> 
                                        {t("DEVELOPMENT_LABEL_FIFTH_")}
                                        <p>
                                            {t("DEVELOPMENT_LABEL_SIXTH_")}
                                        </p>
                                        <p>
                                            {t("DEVELOPMENT_LABEL_SEVENTH_")}<b>{t("DEVELOPMENT_LABEL_EIGHTH_")}</b>{t("DEVELOPMENT_LABEL_NINETH_")}
                                        </p> 
                                    </li>
                                </ul>
                           </div>
                case 6:
                    return <div>
                                <p>
                                {t("TESTING_AND_QUALITY_LABEL_FIRST_")}<b>{t("TESTING_AND_QUALITY_LABEL_SECOND_")}</b>{t("TESTING_AND_QUALITY_LABEL_THIRD_")}
                                </p>
                                <p>
                                    {t("TESTING_AND_QUALITY_LABEL_FOURTH_")}
                                </p>
                                <p>
                                    {t("TESTING_AND_QUALITY_LABEL_FIFTH_")}
                                </p>
                            </div>
                default:
                    return ''
                }
            })()}
        </div>
    );
}

export default FormatDescription;