import React,{ useEffect, useState } from "react";
import { useSprings, animated, to } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import "./CloudComputing.scss";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import CloudComputingHero from "./../../../assets/imgs/Cloud computing1.jpg";
import CCPageImg from "./../../../assets/imgs/CCPage2.jpg";
import CloudComputing from "./../Design/Design";



// These two are just helpers, they curate spring data, values that are later being interpolated into css
const till = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
})
const from = (_i) => ({ x: 0, rot: 0, scale: 1.5, y: 0 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) => `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

function Design() {
  const { t, i18n } = useTranslation();

  let labels = [
    t("ACCESSIBILITY_ANYWHERE_LABEL_"),
    t("CENTRALIZED_DATA_SECURITY_LABEL_"),
    t("HIGHER_PERFORMANCE_AND_AVAILABILITY_LABEL_"),
    t("INSTANT_BUSINESS_INSIGHTS_LABEL_"),
    t("PRICE_PERFORMANCE_LABEL_"),
    t("VIRTUALIZED_COMPUTING_LABEL_")
  ]

  const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
  const [cards, api] = useSprings(labels.length, i => ({
    ...till(i),
    from: from(i),
  })) // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(({ args: [index], active, movement: [mx], direction: [xDir], velocity: [vx] }) => {
    const trigger = vx > 0.09 // If you flick hard enough it should trigger the card to fly out
    if (!active && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
    api.start(i => {
      if (index !== i) return // We're only interested in changing spring-data for the current spring
      const isGone = gone.has(index)
      const x = isGone ? (100 + window.innerWidth) * (xDir == 1 ? xDir*-1 : xDir)  : active ? mx : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
      const rot = mx / 100 + (isGone ? xDir * 10 * vx : 0) // How much the card tilts, flicking it harder makes it rotate faster
      const scale = active ? 1.1 : 1 // Active cards lift up a bit
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: active ? 800 : isGone ? 200 : 500 },
      }
    })
    if (!active && gone.size === labels.length)
      setTimeout(() => {
        gone.clear()
        api.start(i => till(i))
      }, 600)
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container_section">
      <div className="cloud-hero-section">
        <h1>{t("CLOUD_COMPUTING_IMAGE_TITLE_LABEL_")}</h1>
      </div>
      <section id="features" className="features">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>{t("CLOUD_COMPUTING_PAGE_TITLE_LABEL_")}</h2>
            <p>{t("CLOUD_COMPUTING_PAGE_TEXT_LABEL_")}</p>
          </header>

          <Row className="row_data_design">
            <Col className="row_data_design_img" lg={6}>
              <img
                className="img-fluid"
                src={CCPageImg}
                alt=""
              />
            </Col>
            <Col
              className="cloud-computing-cards"
            >
            {console.log(cards)}
            {
              cards.map(({ x, y, rot, scale }, i) => (
                <animated.div key={i} style={{ x,y }}>
                  <animated.div {...bind(i)} style={{ transform: to([rot, scale], trans)}}>
                    <h2>{labels[i]}</h2>
                  </animated.div>
                </animated.div>
              ))
            }
              {/* <div className="row_data align-self-center gy-4">
                <div className="data-container">
                  <div data-aos="zoom-out" data-aos-delay="200">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check">
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      <h3>{t("ACCESSIBILITY_ANYWHERE_LABEL_")}</h3>
                    </div>
                  </div>
                  <div data-aos="zoom-out" data-aos-delay="300">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check">
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      <h3>{t("CENTRALIZED_DATA_SECURITY_LABEL_")}</h3>
                    </div>
                  </div>
                  <div data-aos="zoom-out" data-aos-delay="400">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check">
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      <h3>{t("HIGHER_PERFORMANCE_AND_AVAILABILITY_LABEL_")}</h3>
                    </div>
                  </div>
                </div>
                <div className="data-container">
                  <div data-aos="zoom-out" data-aos-delay="500">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check">
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      <h3>{t("INSTANT_BUSINESS_INSIGHTS_LABEL_")}</h3>
                    </div>
                  </div>
                  <div data-aos="zoom-out" data-aos-delay="600">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check">
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      <h3>{t("PRICE_PERFORMANCE_LABEL_")}</h3>
                    </div>
                  </div>
                  <div data-aos="zoom-out" data-aos-delay="700">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check">
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      <h3>{t("VIRTUALIZED_COMPUTING_LABEL_")}</h3>
                    </div>
                  </div>
                </div>
              </div> */}
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
}

export default Design;
