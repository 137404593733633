import React from "react";
import "./Services.scss";

import { useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PopupModal from "../../components/PopupModal/PopupModal";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

import ProgrammingTags from "./../../assets/icons/languages/programmingTags.png";
import MobileCard from "./../../assets/icons/languages/mobileCard.png";
import WebCard from "./../../assets/icons/languages/webCard.png";
import DBCard from "./../../assets/icons/languages/dbCard.png";
import CloudCard from "./../../assets/icons/languages/cloudCard.png";
import { LangModel } from "../../Models/langModel";
import { langSubObjectModel } from "../../Models/langSubObjectModel";

import Phases from "../../assets/icons/phases/phases.png";

import ReasearchAndAnalysisIcon from "../../assets/icons/phases/ReasearchAndAnalysis.png";
import planningAndStrategyIcon from "../../assets/icons/phases/planningAndStrategy.png";
import DesigningIcon from "../../assets/icons/phases/Designing.png";
import ContentCreatingIcon from "../../assets/icons/phases/ContentCreating.png";
import DevelopmentIcon from "../../assets/icons/phases/Development.png";
import TestingAndQAIcon from "../../assets/icons/phases/TestingAndQA.png";

import ReasearchAndAnalysisModal from "../../assets/icons/ModalImages/ReasearchAndAnalysis.png";
import PlanningAndStrategyModal from "../../assets/icons/ModalImages/planningAndStrategy.png";
import DesigningModal from "../../assets/icons/ModalImages/Designing.png";
import ContentCreatingModal from "../../assets/icons/ModalImages/ContentCreating.png";
import DevelopmentModal from "../../assets/icons/ModalImages/Development.png";
import TestingAndQAModal from "../../assets/icons/ModalImages/TestingAndQA.png";
import UIUXDesignImage from "../../assets/imgs/UIUXDesign-square.png";
import webDesignsquareImage from "../../assets/imgs/webDesign-square.png";
import MobileDevelopmentImage from "../../assets/imgs/MobileDevelopment-square.png";
import CloudcomputingImage from "../../assets/imgs/Cloudcomputing-square.png";
import { useTranslation } from "react-i18next";

import ServicesHeroImage from "./../../assets/imgs/services.jpg";

function Services() {
  const [isOpen, setIsOpen] = useState(false);

  const [modalImage, setModalImage] = useState("");
  const [id, setId] = useState("");

  function clossPopup() {
    setIsOpen(false);
  }

  function viewcontent(id) {
    setIsOpen(true);

    switch (id) {
      case "ResearchAndAnalysis":
        setModalImage(ReasearchAndAnalysisModal);
        setId(1);
        break;
      case "PlanningAndStrategy":
        setModalImage(PlanningAndStrategyModal);
        setId(2);
        break;
      case "Designing":
        setModalImage(DesigningModal);
        setId(3);
        break;
      case "ContentCreation":
        setModalImage(ContentCreatingModal);
        setId(4);
        break;
      case "Development":
        setModalImage(DevelopmentModal);
        setId(5);
        break;
      case "TestingAndQualityAssurance":
        setModalImage(TestingAndQAModal);
        setId(6);
        break;
      default:
        setModalImage("https://via.placeholder.com/150");
        setId();
    }
  }

  function addObject(id, name) {
    return new LangModel(id, name);
  }

  function addSubObject(id, parentId, name, image, description) {
    return new langSubObjectModel(id, parentId, name, image, description);
  }

  const languages = [
    addObject(1, "java"),
    addObject(2, "javascript"),
    addObject(3, "dart"),
    addObject(4, "typescript"),
    addObject(5, "php"),
    addObject(6, "sql"),
    addObject(7, "python"),
    addObject(8, "C#"),
    addObject(9, "c/c++"),
  ];

  const mobile = [
    addSubObject(
      1,
      1,
      "Android",
      MobileCard,
      "An Android developer is responsible for developing applications for devices powered by the Android operating system"
    ),
    addSubObject(2, 2, "React Native", MobileCard, "description"),
    addSubObject(3, 4, "Ionic", MobileCard, "description"),
    addSubObject(4, 8, "Xamarin", MobileCard, "description"),
    addSubObject(5, 3, "flutter", MobileCard, "description"),
  ];

  const web = [
    addSubObject(1, 4, "Angular", WebCard, "description"),
    addSubObject(2, -1, "HTML5", WebCard, "description"),
    addSubObject(3, 5, "Laravel", WebCard, "description"),
    addSubObject(4, 4, "React", WebCard, "description"),
    addSubObject(5, -1, "Bootstrap", WebCard, "description"),
    addSubObject(6, 4, "Ionic", WebCard, "description"),
    addSubObject(7, 2, "javascript", WebCard, "description"),
    addSubObject(8, -1, "SCSS", WebCard, "description"),
    addSubObject(9, 7, "Django", WebCard, "description"),
    addSubObject(10, -1, "Tailwind", WebCard, "description"),
  ];

  const dataBaseManagementSystem = [
    addSubObject(1, 6, "MySQL", DBCard, "description"),
    addSubObject(2, 6, "PostgreSQL", DBCard, "description"),
    addSubObject(3, 6, "MicrosoftSQL", DBCard, "description"),
  ];

  const cloud = [
    addSubObject(1, 0, "Amazon Web Service (AWS)", CloudCard, "description"),
    addSubObject(2, 0, "Google Cloud", CloudCard, "description"),
  ];

  const [languagesData, setlanguagesData] = useState(languages);
  const [mobileData, setMobileData] = useState(mobile);
  const [webData, setwebData] = useState(web);
  const [dataBaseManagementSystemData, setdataBaseManagementSystemData] =
    useState(dataBaseManagementSystem);
  const [cloudData, setcloudData] = useState(cloud);

  const [HeaderTitle, setHeaderTitle] = useState("Languages");
  const [CardImage, setCardImage] = useState(ProgrammingTags);
  const [description, setDescription] = useState("");
  const [showdescriptionflag, setshowdescriptionflag] = useState(false);

  function modifyArrays(array, ModifiedArray, id) {
    array.forEach((element) => {
      if (element.parentId === id) {
        ModifiedArray.push(element);
      }
    });
    return ModifiedArray;
  }

  function onlanguageclicked(item) {
    const mobileArray = [];
    const webArray = [];
    const dataBaseManagementSystemArray = [];
    const cloudArray = [];

    setMobileData(modifyArrays(mobile, mobileArray, item.id));
    setwebData(modifyArrays(web, webArray, item.id));
    setdataBaseManagementSystemData(
      modifyArrays(
        dataBaseManagementSystem,
        dataBaseManagementSystemArray,
        item.id
      )
    );
    setcloudData(modifyArrays(cloud, cloudArray, item.id));
  }

  function reset() {
    setlanguagesData(languages);
    setMobileData(mobile);
    setwebData(web);
    setdataBaseManagementSystemData(dataBaseManagementSystem);
    setcloudData(cloud);
    setshowdescriptionflag(false);
    setHeaderTitle("Languages");
    setCardImage(ProgrammingTags);
  }

  function resetCards() {
    setMobileData(mobile);
    setwebData(web);
    setdataBaseManagementSystemData(dataBaseManagementSystem);
    setcloudData(cloud);
  }

  function frameClicked(item) {
    setshowdescriptionflag(true);
    setHeaderTitle(item.name);
    setDescription(item.description);
    setCardImage(item.image);
    resetCards();
  }

  const { t, i18n } = useTranslation();

  return (
    <div className="container_section">
      <section className="service_c section-bg">
        <div className="section-title">
          <h2>{t("OUR_SERVICES_LABEL_")}</h2>
        </div>
        <Container className="services_container" data-aos="fade-up">
          <Row>
            <Col lg={4}>
              <a href="/services/webdevelopment">
                <div
                  className="member d-flex align-items-start"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="pic">
                    <img className="img-fluid" src={webDesignsquareImage} alt="" />
                  </div>
                  <div className="member-info">
                    <h4>{t("WEB_APPLICATION_LABEL_")}</h4>
                    <p>{t("WEB_APPLICATION_DEVELOPMENT_DESCRIPTION_LABEL_")}</p>
                  </div>
                </div>
              </a>
            </Col>
            <Col lg={4} className="service_card_options mt-lg-0">
              <a href="/services/MobileDevelopment">
                <div
                  className="member d-flex align-items-start"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <div className="pic">
                    <img className="img-fluid" src={MobileDevelopmentImage} alt="" />
                  </div>
                  <div className="member-info">
                    <h4>{t("MOBILE_APPLICATION_LABEL_")}</h4>
                    <p>{t("MOBILE_APPLICATION_DEVELOPMENT_DESCRIPTION_LABEL_")}</p>
                  </div>
                </div>
              </a>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="mt-4">
              <a href="/services/cloudcomputing">
                <div
                  className="member d-flex align-items-start"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <div className="pic">
                    <img className="img-fluid" src={CloudcomputingImage} alt="" />
                  </div>
                  <div className="member-info">
                    <h4>{t("CLOUD_SERVICES_LABEL_")}</h4>
                    <p>{t("CLOUD_SERVICES_DESCRIPTION_LABEL_")}</p>
                  </div>
                </div>
              </a>
            </Col>
            <Col lg={4} className="service_card_options mt-4">
              <a href="/services/design">
                <div
                  className="member d-flex align-items-start"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <div className="pic">
                    <img className="img-fluid" src={UIUXDesignImage} alt="" />
                  </div>
                  <div className="member-info">
                    <h4>{t("UIUX_DESIGN_APPLICATION_LABEL_")}</h4>
                    <p>{t("UIUX_DESIGN_APPLICATION_DEVELOPMENT_DESCRIPTION_LABEL_")}</p>
                  </div>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <div className="container">
          <div className="client_section_header">
            <h1 className="title">{t("PRODUCT_LIFECYCLE_MANAGEMENT_LABEL_")}</h1>
          </div>
          <div className="client_section">
            <div className="client_Section_card_main">
              <div className="client_Section_card_body">
                <img
                  className="client_section_image"
                  src={Phases}
                  alt="uiux img"
                />
                <div className="list_items">
                  <div
                    onClick={() => viewcontent("ResearchAndAnalysis")}
                    className="list_item"
                  >
                    <div>
                      <img
                        className="list_img"
                        src={ReasearchAndAnalysisIcon}
                        alt="uiux img"
                      />
                    </div>
                    <div>
                      <div className="list_description">
                      {t("REASEARCH_AND_ANALYSIS_LABEL_")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => viewcontent("PlanningAndStrategy")}
                    className="list_item"
                  >
                    <div>
                      <img
                        className="list_img"
                        src={planningAndStrategyIcon}
                        alt="uiux img"
                      />
                    </div>
                    <div>
                      <div className="list_description">
                        {t("PLANNING_AND_STRATEGY_LABEL_")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => viewcontent("Designing")}
                    className="list_item"
                  >
                    <div>
                      <img
                        className="list_img"
                        src={DesigningIcon}
                        alt="uiux img"
                      />
                    </div>
                    <div>
                      <div className="list_description">{t("DESIGNING_LABEL_")}</div>
                    </div>
                  </div>
                </div>
                <div className="list_items">
                  <div
                    onClick={() => viewcontent("ContentCreation")}
                    className="list_item"
                  >
                    <div>
                      <img
                        className="list_img"
                        src={ContentCreatingIcon}
                        alt="uiux img"
                      />
                    </div>
                    <div>
                      <div className="list_description">{t("CONTENT_CREATION_LABEL_")}</div>
                    </div>
                  </div>
                  <div
                    onClick={() => viewcontent("Development")}
                    className="list_item"
                  >
                    <div>
                      <img
                        className="list_img"
                        src={DevelopmentIcon}
                        alt="uiux img"
                      />
                    </div>
                    <div>
                      <div className="list_description">{t("DEVELOPENT_LABEL_")}</div>
                    </div>
                  </div>
                  <div
                    onClick={() => viewcontent("TestingAndQualityAssurance")}
                    className="list_item"
                  >
                    <div>
                      <img
                        className="list_img"
                        src={TestingAndQAIcon}
                        alt="uiux img"
                      />
                    </div>
                    <div>
                      <div className="list_description">
                        {t("TESTING_AND_QUALITY_LABEL_")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen ? (
          <PopupModal
            modalImage={modalImage}
            id={id}
            isOpen={isOpen}
            clossPopup={clossPopup}
          />
        ) : (
          ""
        )}
      </section>
      <section className="service_section d-none">
        <div className="header_plang_section">
          <h1>{t("OUR_TOOLS_UNDER_YOUR_DISPOSAL_LABEL_")}</h1>
        </div>
        <div className="service_lang_usage_container">
          <div className="service_card_section_lang">
            {!showdescriptionflag ? (
              <Card className="card_service">
                <Card.Body className="card_body_lang">
                  <Card.Title className="card_text_service">
                    <img className="programming_tags" src={CardImage} alt="" />
                    {HeaderTitle}
                    <button onClick={() => reset()}>{t("RESET_LABEL_")}</button>
                  </Card.Title>
                  <ul>
                    {languagesData.map((item) => {
                      return (
                        <li onClick={() => onlanguageclicked(item)}>
                          {item.name}
                        </li>
                      );
                    })}
                  </ul>
                </Card.Body>
              </Card>
            ) : (
              <Card className="card_service">
                <Card.Body className="card_body_lang">
                  <Card.Title className="card_text_service">
                    <img className="programming_tags" src={CardImage} alt="" />
                    {HeaderTitle}
                    <button onClick={() => reset()}>{t("RESET_LABEL_")}</button>
                  </Card.Title>
                  <p>{description}</p>
                </Card.Body>
              </Card>
            )}
          </div>
          <div className="service_lang_usage">
            <div
              className={
                mobileData.length !== 0
                  ? "card_service_lang_usage"
                  : "hide_card"
              }
            >
              <Card className="card_service_langs">
                <Card.Body className="card_body">
                  <Card.Title className="card_text_service">
                    <img className="programming_tags" src={MobileCard} alt="" />
                     {t("MOBILE_LABEL_")}
                  </Card.Title>
                  <ul>
                    {mobileData.map((item) => {
                      return (
                        <li onClick={() => frameClicked(item)}>{item.name}</li>
                      );
                    })}
                  </ul>
                </Card.Body>
              </Card>
            </div>
            <div
              className={
                webData.length !== 0 ? "card_service_lang_usage" : "hide_card"
              }
            >
              <Card className="card_service_langs">
                <Card.Body className="card_body">
                  <Card.Title className="card_text_service">
                    <img className="programming_tags" src={WebCard} alt="" />
                    {t("WEB_LABEL_")}
                  </Card.Title>
                  <ul>
                    {webData.map((item) => {
                      return (
                        <li onClick={() => frameClicked(item)}>{item.name}</li>
                      );
                    })}
                  </ul>
                </Card.Body>
              </Card>
            </div>
            <div
              className={
                dataBaseManagementSystemData.length !== 0
                  ? "card_service_lang_usage"
                  : "hide_card"
              }
            >
              <Card className="card_service_langs">
                <Card.Body className="card_body">
                  <Card.Title className="card_text_service">
                    <img className="programming_tags" src={DBCard} alt="" />
                    {t("DATABASE_MANAGEMET_SYSTEM_LABEL_")}
                  </Card.Title>
                  <ul>
                    {dataBaseManagementSystemData.map((item) => {
                      return (
                        <li onClick={() => frameClicked(item)}>{item.name}</li>
                      );
                    })}
                  </ul>
                </Card.Body>
              </Card>
            </div>
            <div
              className={
                cloudData.length !== 0 ? "card_service_lang_usage" : "hide_card"
              }
            >
              <Card className="card_service_langs">
                <Card.Body className="card_body">
                  <Card.Title className="card_text_service">
                    <img className="programming_tags" src={CloudCard} alt="" />
                    {t("CLOUD_LABEL_")}
                  </Card.Title>
                  <ul>
                    {cloudData.map((item) => {
                      return (
                        <li onClick={() => frameClicked(item)}>{item.name}</li>
                      );
                    })}
                  </ul>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
