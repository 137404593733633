import React from "react";

import { useTranslation } from "react-i18next";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const selectedClass = localStorage.getItem("language");

  return (
    <div className="lang-selector">
      <span
        className={`en headerItem ${selectedClass === "en" ? "selected" : ""}`}
        onClick={(e) => changeLanguage("en")}
      >
        En
      </span>
      <span
        className={`de headerItem ${selectedClass === "de" ? "selected" : ""}`}
        onClick={(e) => changeLanguage("de")}
      >
        De
      </span>
    </div>
  );
};

export default LanguageSelector;
