import "./Contact.scss";
import { React, useEffect } from "react";
import { Axios } from "axios";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Iframe from "react-iframe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocation,
  faEnvelope,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import contactusImage from "./../../assets/imgs/Contact.jpg";

function Contact() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container_section contact-page">
      <img className="hero_img" src={contactusImage} alt="Responsive img" />
      <section id="contact" className="contact">
        <Container data-aos="fade-up">
          <div className="section-title">
            <h2>{t("CONTACT_PAGE_TITLE_LABEL_")}</h2>
            <p>{t("CONTACT_PAGE_TEXT_LABEL_")}</p>
          </div>
          <Row className="row_data row_data_with_padding ">
            <Col lg={5} className="general_info d-flex align-items-stretch">
              <div className="info">
                <div className="address_detail">
                  <i className="bi bi-geo-alt">
                    <FontAwesomeIcon icon={faLocation} size="1x" />
                  </i>
                  <h4>{t("LOCATION_LABEL_")}</h4>
                  <p>Sturzeneggstrasse 38, 9015 St. Gallen, Switzerland</p>
                </div>
                <div className="email">
                  <i className="bi bi-envelope">
                    <FontAwesomeIcon icon={faEnvelope} size="1x" />
                  </i>
                  <h4>{t("EMAIL_LABEL_")}</h4>
                  <p>tech@metamorph-sd.com</p>
                </div>
                <Iframe
                  src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Sturzeneggstrasse 38 9015 St. Gallen&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  frameborder="0"
                  className="map"
                  allowfullscreen
                ></Iframe>
              </div>
            </Col>
            <Col
              lg={7}
              className="form_data mt-5 mt-lg-0 d-flex align-items-stretch"
            >
              <Form method="post" role="form" className="email-form">
                <Row className="name-email">
                  <Col md="6" className="form_input form_input_name form-group">
                    <Form.Group
                      className="form_content mb-3"
                      controlId="formName"
                    >
                      <Form.Label>{t("YOUR_NAME_LABEL_")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6" className="form_input form-group">
                    <Form.Group
                      className="form_content mb-3"
                      controlId="formEmail"
                    >
                      <Form.Label>{t("YOUR_EMAIL_LABEL_")}</Form.Label>
                      <Form.Control
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-group">
                  <Form.Group
                    className="form_content mb-3"
                    controlId="formSubject"
                  >
                    <Form.Label>{t("SUBJECT_LABEL_")}</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      required
                    />
                  </Form.Group>
                </div>
                <div className="form-group">
                  <Form.Group
                    className="form_content mb-3"
                    controlId="formMessage"
                  >
                    <Form.Label>{t("MESSAGE_LABEL_")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      className="form-control"
                      name="message"
                      id="message"
                      required
                    />
                  </Form.Group>
                </div>
                <div className="my-3">
                  <div className="loading">{t("LOADING_LABEL_")}</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    {t("MESSAGE_SENT_LABEL_")}
                  </div>
                </div>
                <div className="contact_submit_btn">
                  <Button type="submit">{t("SEND_MESSAGE_LABEL_")}</Button>{" "}
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Contact;
