import { React, useEffect } from "react";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

function Header() {
  return (
    <>
      <DesktopHeader />
      <MobileHeader />
    </>
  );
}

export default Header;
