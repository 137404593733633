import React from "react";

import { useTranslation } from "react-i18next";

import "./Footer.scss";
import logo from "../.././assets/logo/logo 1.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPaperPlane,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

function Footer() {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("language");

  return (
    <footer>
      <div className="footer-wrap">
        <div className="second_class">
          <Container className="second_class_bdr">
            <Row>
              <Col className="second_class_second_column" md="4" sm={4}>
                <div className="footer-logo">
                  <img className="logo" src={logo} alt="logo" />
                </div>
              </Col>
              <Col className="quick-links" md="2" sm={4}>
                <h3>{t("QUICK_LINKS_LABEL_")}</h3>
                <ul className="footer-links">
                  <li>
                    <FontAwesomeIcon
                      className="arrow_icon"
                      icon={faAngleRight}
                    />
                    <a href="/">{t("HOME_LABEL_")}</a>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      className="arrow_icon"
                      icon={faAngleRight}
                    />
                    <a href="/about">{t("ABOUT_US_LABEL_")}</a>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      className="arrow_icon"
                      icon={faAngleRight}
                    />
                    <a href="/contact">{t("CONTACT_US_LABEL_")}</a>
                  </li>
                </ul>
              </Col>
              <Col className="services-footer" md="3" sm={4}>
                <h3>{t("OUR_SERVICES_LABEL_")}</h3>
                <ul className="footer-category">
                  <li>
                    <FontAwesomeIcon
                      className="arrow_icon"
                      icon={faAngleRight}
                    />
                    <a href="/services/webdevelopment">
                      {t("WEB_DEVELOPMENT_LABEL_")}
                    </a>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      className="arrow_icon"
                      icon={faAngleRight}
                    />
                    <a href="/services/MobileDevelopment">
                      {t("MOBILE_DEVELOPMENT_LABEL_")}
                    </a>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      className="arrow_icon"
                      icon={faAngleRight}
                    />
                    <a href="/services/cloudcomputing">
                      {t("CLOUD_SYSTEMS_LABEL_")}
                    </a>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      className="arrow_icon"
                      icon={faAngleRight}
                    />
                    <a href="/services/design">{t("UI_UX_DESIGN_LABEL_")}</a>
                  </li>
                  {/* <li>
                                <FontAwesomeIcon className="arrow_icon" icon={faAngleRight} /><a href="/#">Trideus - Campus Ambassadors</a>
                            </li> */}
                </ul>
                <div className="clearfix"></div>
              </Col>
              <Col md="4" sm={4} className="social-links-container">
                <Col md="12">
                  <div className="standard_social_links">
                    <div>
                      <li className="round-btn btn-facebook">
                        <a className="links" href="https://www.facebook.com/people/Metamorph-System-Development/100087427249606/" target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faFacebook} size="2x" />
                        </a>
                      </li>
                      <li className="round-btn btn-instagram">
                        <a className="links" href="https://www.instagram.com/metamorphsystemdevelopment/" target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faInstagram} size="2x" />
                        </a>
                      </li>
                      <li className="round-btn btn-linkedin">
                        <a className="links" href="https://www.linkedin.com/company/metamorph-system-development/" target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                        </a>
                      </li>
                      <li className="round-btn btn-envelop">
                        <a className="links" href="mailto:tech@metamorph-sd.com" target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faEnvelope} size="2x" />
                        </a>
                      </li>
                    </div>
                  </div>
                </Col>
                <div className="clearfix"></div>
                <Col md="12">
                  <h3 className="Footer_connected">
                    {t("STAY_CONNECTED_LABEL_")}
                  </h3>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>
        <Row className="row">
          <Container fluid>
            <div className="copyright">&copy;{t("COPY_RIGHT_LABEL_")}</div>
          </Container>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
