import { React, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import logo from "./../../assets/logo/logo 1.png";
import WebDevelopment from "./../services/WebDevelopment/WebDevelopment";
import { useTranslation } from "react-i18next";

function MobileHeader() {
  const { t, i18n } = useTranslation();
  return (
    <div className="mobile-header">
      <a href="/">
        <img src={logo} alt="logo" />
      </a>
      <Menu right>
        <a className="mobile-menu-logo" href="/">
          <img src={logo} alt="logo" />
        </a>
        <a id="home" className="menu-item" href="/">
          {t("HOME_LABEL_")}
        </a>
        <a className="menu-item--small" href="/services/webdevelopment">
          {t("WEB_DEVELOPMENT_LABEL_")}
        </a>
        <a className="menu-item--small" href="/services/mobiledevelopment">
          {t("MOBILE_DEVELOPMENT_LABEL_")}
        </a>
        <a className="menu-item--small" href="/services/design">
          {t("UI_UX_DESIGN_LABEL_")}
        </a>
        <a className="menu-item--small" href="/services/cloudcomputing">
          {t("CLOUD_SYSTEMS_LABEL_")}
        </a>
        <a id="about" className="menu-item" href="/about">
          {t("ABOUT_US_LABEL_")}
        </a>
        <a id="contact" className="menu-item" href="/contact">
          {t("CONTACT_US_LABEL_")}
        </a>
      </Menu>
    </div>
  );
}

export default MobileHeader;