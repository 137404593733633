import React from "react";
import { useEffect } from "react";
import "./MobileDevelopment.scss";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tilt from "react-parallax-tilt";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faDraftingCompass,
  faHandshakeSimple,
  faPhoneVolume,
  faMagic,
  faPeopleCarry,
} from "@fortawesome/free-solid-svg-icons";

import MobileDevelopmentHeroImage from "./../../../assets/imgs/Mobile development21.jpg";
import PhoneSecImage from "./../../../assets/imgs/phone_sec.jpg";

import { useTranslation } from "react-i18next";

function MobileDevelopment() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container_section">
      <div className="mobile-hero">
        <h1>{t("MOBILE_DEVELOPMENT_LABEL_")}</h1>
        <p>
        {t("MOBILE_DEVELOPMENT_PAGE_TITLE_LABEL_")}
        </p>
      </div>
      <section id="alt-services" className="alt-services">
        <Container fluid data-aos="fade-up">
          <Row className="row_data justify-content-around gy-4">
            <Col
              lg={6}
              className="img-bg"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <img
                className="img-mobile"
                src={PhoneSecImage}
                alt="Responsive img"
              />
            </Col>
            <Col lg={5} className="data_section">
              <div className="data_section_details">
                <Tilt
                  tiltReverse="true"
                  className="tilt-container"
                  style={{ marginBottom: "20px" }}
                >
                  <div
                    className="icon-box box_data"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div>
                      <h4 className="stretched-link">
                        {t("YOUR_BUSINESS_IS_OUR_BUSINESS_LABEL_")}
                      </h4>
                      <p>{t("YOUR_BUSINESS_IS_OUR_BUSINESS_TEXT_LABEL_")}</p>
                    </div>
                  </div>
                </Tilt>
                <Tilt
                  tiltReverse="true"
                  className="tilt-container"
                  style={{ marginBottom: "20px" }}
                >
                  <div
                    className="icon-box box_data"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div>
                      <h4 className="stretched-link">
                        {t("STARTUPS_TO_ENTERPRISES_LABEL_")}
                      </h4>
                      <p>{t("STARTUPS_TO_ENTERPRISES_TEXT_LABEL_")}</p>
                    </div>
                  </div>
                </Tilt>
                <Tilt tiltReverse="true" className="tilt-container">
                  <div
                    className="icon-box box_data"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div>
                      <h4 className="stretched-link">
                        {t("CUSTOMIZED_AND_OPTIMIZED_LABEL_")}
                      </h4>
                      <p>{t("CUSTOMIZED_AND_OPTIMIZED_TEXT_LABEL_")}</p>
                    </div>
                  </div>
                </Tilt>
                <Tilt tiltReverse="true" className="tilt-container">
                  <div
                    className="icon-box box_data"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div>
                      <h4 className="stretched-link">
                        {t("HAPPY_EVER_AFTER_LABEL_")}
                      </h4>
                      <p>{t("HAPPY_EVER_AFTER_TEXT_LABEL_")}</p>
                    </div>
                  </div>
                </Tilt>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default MobileDevelopment;
