import './App.css';
import Header from './Views/Header/Header.js';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Home from './Views/Home/Home.tsx';
import Contact from './Views/Contact/Contact.js';
import About from './Views/About/About.js';
import React, { Component, Suspense } from "react";
import Footer from './Views/Footer/Footer.js';

import WebDevelopment from "./Views/services/WebDevelopment/WebDevelopment";
import MobileDevelopment from "./Views/services/MobileDevelopment/MobileDevelopment";
import Design from "./Views/services/Design/Design";
import CloudComputing from "./Views/services/CloudComputing/CloudComputing";

import Services from "./Views/services/Services";


import "./helper/i18n";
import LanguageSelector from "./helper/languageselector"
import { useTranslation, withTranslation, Trans } from "react-i18next";


// function TransComponent() {
//   return <Trans i18nKey="description.part1" />;
// }

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);


function App() {
  return (
    // <div className="App">
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
      <Header/>
      <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/services/WebDevelopment' element={<WebDevelopment />}/>
          <Route path='/services/MobileDevelopment' element={<MobileDevelopment />}/>
          <Route path='/services/Design' element={<Design />}/>
          <Route path='/services/CloudComputing' element={<CloudComputing />}/>
          <Route path="*" element={<Navigate to="/" replace />} />
      </Routes> 
      <Footer/>
    </Suspense>
    </BrowserRouter>
    // </div>
  );
}

export default App;
