import React from "react";

import { useState, useRef } from "react";
import { Slider } from "infinite-react-carousel";
import "../../assets/styles/carousel.scss";
import "./Home.scss"
import Services from "../services/Services";
import "../../assets/styles/variable.scss";
import {Link} from 'react-router-dom';
import heroImage from "../../assets/imgs/HomeCollage2.jpg";
import heroImageMob from "../../assets/imgs/HomeCollage3.jpg";
// import CardComponent from '../../components/cards/cards'
import { useTranslation } from "react-i18next";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Carousel, CarouselItem, CarouselProps } from 'react-round-carousel';


import { CardModel } from '../../Models/cardModel';

import Overlay from 'react-bootstrap/Overlay';

import devIcon from '../../assets/icons/dev.png';
import webApplication from '../../assets/icons/webApplication.png';
import MobileApplication from '../../assets/icons/MobileApplication.png';
import DevelopmentTeam from '../../assets/icons/DevelopmentTeam.png';
import Devops from '../../assets/icons/Devops.png';
import UIUX from '../../assets/icons/UIUX.png';
import QATesing from '../../assets/icons/QATesing.png';


import technologyURL from '../../assets/imgs/technology.jpg';
import TeamURL from '../../assets/imgs/desk.jpg';
import technology2URL from '../../assets/imgs/technology2.jpg';


import HeroBackground from '../../assets/imgs/hero_background.png';
import AlbertEinstein from '../../assets/imgs/AlbertEinstein.png';
import AlbertEinsteinAnimated from '../../assets/imgs/einstein-2.gif';


import HomeAboutus from '../../assets/imgs/home-about-us.jpg';

 
function Home(){

    
    const data = {
        services : [
            new CardModel(1,webApplication,'Web Application Development ', 'description1'),
            new CardModel(2,MobileApplication,'Mobile Application Development ', 'description2'),
            new CardModel(3,DevelopmentTeam,'Development Team', 'description3'),
            new CardModel(5,UIUX,'UI/UX', 'description3'),
        ],
        technology : [
            new CardModel(7,devIcon,'Cloud systems', 'description1'),
            new CardModel(8,devIcon,'title22', 'description2'),
            new CardModel(9,devIcon,'title33', 'description3')
        ],
    }

    const [cards, setCards] = useState(data['services']);

    const [isactive, setIsActive] = useState('services');

    const [windowSize, setWindowSize] = useState({innerWidth, innerHeight} = window);


    const [show, setShow] = useState(false);

    const target = useRef(null);

    function onServices(){
        setCards(data['services']);
        setIsActive('services');
    };

    function onTechno(){
        setCards(data['technology']);
        setIsActive('technology');
        
    };


    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        className: "slide_box",
        dots: false,
        virtualList: true,
        duration: 20,
        arrows:true,
    };


const propp :  number = 25;
// Create an array of Carousel Items
const items: CarouselItem[] = Array(cards.length)
	.fill('')
	.map((_: string, index: number) => ({
		alt: '',
		image: cards[index].icon,
		content: (
			<div>
				<strong>{cards[index].title}</strong>
				<span>{cards[index].description}</span>
			</div>
		)
	}));


    const { t, i18n } = useTranslation();



    return(
        <div className="section-offers">   
            {windowSize.innerWidth > 768 ? <img src={heroImage} alt="home-page-hero" className="home-hero-desktop"/>: <img src={heroImageMob} alt="home-page-hero" className="home-hero-desktop"/>}
            <section>
                <div className="container">
                    <div className="offers">
                        <div className="offers-title">
                            <h1 className="title">{t("WHAT_WE_OFFER_LABEL_")}</h1>
                        </div>
                        <div className="services">
                            <Services/>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container hero_container">
                    <div className="hero_content">
                        <div className="hero_background"  ></div>
                        <div className="hero_header">
                            <img className="albert" onClick={() => setShow(!show)} src={AlbertEinstein}  alt="Responsive img"/>
                            <h1 className="hero_data" >{t("LOGIC_ALBERT_EINSTEIN_LABEL_")}</h1>
                            <p className="hero_data">{t("ALBERT_EINSTEIN_LABEL_")}</p>
                        </div>
                        <img  src={AlbertEinsteinAnimated} className={show ? 'animated_img visible' : 'not_visible'}  alt="Responsive img"/>
                    </div>
                    <div className="animate-me change_section">
                        <div className="change_subSection">
                            <h1>{t("THE_CHANGE_IS_NOW_LABEL_")}</h1>
                            <h3>{t("THE_CHANGE_DESC_LABEL_")}</h3>
                            <p>{t("WE_ARE_ENTHUSIASTS_LABEL_")}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="cta" className="cta">
                <Container data-aos="zoom-out">
                    <Row className="g-5 row_data">
                        <Col md="6" lg={8} className="content d-flex flex-column justify-content-center order-last order-md-first">
                            <h3>{t("MEET_THE_TEAM_LABEL_")}</h3>
                            <p>{t("MEET_THE_TEAM_DESCRIPTION_LABEL_")}</p>
                            {/* <a className="cta-btn align-self-start" href="#">About us</a> */}
                            <Link className='cta-btn align-self-start' to='/about'>{t("ABOUT_US_LABEL_")} </Link>
                        </Col>
                        <Col md="6" lg={4} className="order-first order-md-last">
                            <div className="img">
                                <img src={HomeAboutus} className="img-fluid" alt=""/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> 
        </div>
    );
}

export default Home;
 