import "./About.scss";
import { React } from "react";
import { useEffect } from "react";
import { Slider } from "infinite-react-carousel";
import { useTranslation } from "react-i18next";

// import aboutImg1 from '../.././assets/imgs/about1.png';
// import aboutImg2 from '../.././assets/imgs/about1.jpg';
import bm1 from "../.././assets/imgs/businessmen1.png";
import bm2 from "../.././assets/imgs/businessmen2.png";
import bm3 from "../.././assets/imgs/businessmen3.png";
import bm4 from "../.././assets/imgs/businessmen4.png";
import bm5 from "../.././assets/imgs/businessmen5.png";

const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  className: "slide_box",
  dots: false,
  virtualList: true,
  duration: 20,
  arrows: false,
};

function About() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="hero-image">
        <div className="hero-text">
          <h1>{t("ABOUT_PAGE_HERO_TITLE_LABEL_")}</h1>
          <h3>
            {t("ABOUT_PAGE_HERO_TEXT_FIRST_LABEL_")}{" "}
            <span id="emphasis">
              {t("ABOUT_PAGE_HERO_TEXT_EMPHASIZED_LABEL_")}
            </span>
            <br />
            {t("ABOUT_PAGE_HERO_TEXT_SECOND_LABEL_")}
          </h3>
        </div>
      </div>
      <div className="bgWhole">
        {window.innerWidth > 480 ? (
          <div className="about-text">
            <h2>{t("WHO_WE_ARE_TITLE_LABEL_")}</h2>
            <h4>
              {t("WHO_WE_ARE_TEXT_FIRST_LABEL_")}
              <br />
              {t("WHO_WE_ARE_TEXT_SECOND_LABEL_")}
              <br />
              {t("WHO_WE_ARE_TEXT_THIRD_LABEL_")}
              <br />
              {t("WHO_WE_ARE_TEXT_FOURTH_LABEL_")}
              <br />
            </h4>

            <h2>{t("WEIRDOS_BUT_PROFESSIONALS_LABEL_")}</h2>
            <h4>
              {t("WEIRDOS_BUT_PROFESSIONALS_TEXT_FIRST_LABEL_")}
              <br />
              {t("WEIRDOS_BUT_PROFESSIONALS_TEXT_SECOND_LABEL_")}
              <br />
              {t("WEIRDOS_BUT_PROFESSIONALS_TEXT_THIRD_LABEL_")}
              <br />
            </h4>
          </div>
        ) : (
          <div className="about-text">
            <h2>{t("WHO_WE_ARE_TITLE_LABEL_")}</h2>
            <h4>{t("WHO_WE_ARE_TEXT_FULL_LABEL_")}</h4>

            <h2>{t("WEIRDOS_BUT_PROFESSIONALS_LABEL_")}</h2>
            <h4>{t("WEIRDOS_BUT_PROFESSIONALS_TEXT_FULL_LABEL_")}</h4>
          </div>
        )}
      </div>
      <div>
        {window.innerWidth > 480 ? (
          <div>
            <div className="about-text">
              <h2>{t("MEET_THE_MORPHLIN_TITLE_LABEL_")}</h2>
            </div>
            <div className="aboutimgs">
              <div className="bmImTxt">
                <img
                  id="bm1"
                  className="sillouhette"
                  src={bm1}
                  alt="sillouhette"
                />
                <h3 className="bmText">AHMAD BAHSOUN AKA BISO</h3>
              </div>
              <div className="bmImTxt">
                <img className="sillouhette" src={bm2} alt="sillouhette" />
                <h3 className="bmText">ALI FADLALLA AKA BIG FAD</h3>
              </div>
              <div className="bmImTxt">
                <img className="sillouhette" src={bm4} alt="sillouhette" />
                <h3 className="bmText">{t("UNKNOWN_MORPHLING_LABEL_")}</h3>
              </div>
              <div className="bmImTxt">
                <img className="sillouhette" src={bm3} alt="sillouhette" />
                <h3 className="bmText">HUSSEIN NOUR ALDEEN AKA HISSO</h3>
              </div>
              <div className="bmImTxt">
                <img className="sillouhette" src={bm2} alt="sillouhette" />
                <h3 className="bmText">HUSE CEJVANOVIC AKA THE VIC</h3>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="about-text">
              <h2>
                {t("MEET_THE_MORPHLIN_TITLE_FIRST_LABEL_")}
                <br /> {t("MEET_THE_MORPHLIN_TITLE_SECOND_LABEL_")}
              </h2>
            </div>
            <div className="mobile-slider-about">
              <Slider {...settings}>
                <div className="row mobile-slider-about">
                  <img
                    src={bm1}
                    className="slide_img col-lg-12 imgSlider"
                    alt=""
                  />
                  <h3 className="bmText">AHMAD BAHSOUN AKA BISO</h3>
                </div>
                <div className="mobile-slider-about">
                  <img
                    src={bm2}
                    className="slide_img col-lg-12 imgSlider"
                    alt=""
                  />
                  <h2 className="bmText">ALI FADLALLA AKA BIG FAD</h2>
                </div>
                <div className="mobile-slider-about">
                  <img
                    src={bm4}
                    className="slide_img col-lg-12 imgSlider"
                    alt=""
                  />
                  <h2 className="bmText">{t("UNKNOWN_MORPHLING_LABEL_")}</h2>
                </div>
                <div className=" mobile-slider-about">
                  <img
                    src={bm3}
                    className="slide_img col-lg-12 imgSlider"
                    alt=""
                  />
                  <h2 className="bmText">HUSSEIN NOUR ALDEEN AKA HISSO</h2>
                </div>
                <div className=" mobile-slider-about">
                  <img
                    src={bm2}
                    className="slide_img col-lg-12 imgSlider"
                    alt=""
                  />
                  <h2 className="bmText">HUSE CEJVANOVIC AKA THE VIC</h2>
                </div>
              </Slider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default About;
